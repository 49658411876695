body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'PermanentMarker';
  src: url('./assets/fonts/PermanentMarker-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


:root {
  --dark-logo: rgba(0, 19, 54, 1);
  --dark-logo-pressed: rgba(0, 7, 19, 1);
  --ink-full-opacity: #352b42;
  --ink: rgba(53, 43, 66, 0.93);
  --background-main: #ffffd1;
  --background-menu: rgba(199, 228, 247, 1);
  --background-play-session: #ffebb5;
  --background-4: #ffd7ff;
  --background-settings: #bfc8e3;
  --true-white: #ffffff;
  --off-white: #ffffd1;
  --light-pink: rgba(255, 238, 255, 1);
}
